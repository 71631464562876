<template>
  <div>
    <div class="row type-btn" v-if="toeflTags.length > 0">
      <div
        class="col-md-3 col-sm-6 col-xs-6"
        :key="toeflTag.slug.en"
        v-for="toeflTag in toeflTags"
      >
        <template v-if="isProToeflTag(toeflTag)">
          <el-tooltip
            v-if="isFreeMember"
            class="item"
            effect="dark"
            :content="$t('toefl.Pro Plans')"
            placement="top"
          >
            <el-button class="btn" disabled style="border: none;">
              <i class="fas fa-lock" />
              <span
                v-if="
                  toeflTag.slug.en == 'tpo-1-66' ||
                    toeflTag.slug.en == 'tpo-67-75'
                "
              >
                TestAdmit Mock 1
              </span>
              <span v-else>
                {{ toeflTag.name[lang] || toeflTag.name.en }}
              </span>
            </el-button>
          </el-tooltip>
          <span
            v-else
            :class="
              `btn ${selectedToeflTag === toeflTag.slug.en ? 'active' : ''}`
            "
            @click="() => selectToeflTags(toeflTag.slug.en)"
          >
            <i class="fas fa-unlock" />
            {{ toeflTag.name[lang] || toeflTag.name.en }}
          </span>
        </template>
        <span
          v-else
          :class="
            `btn ${selectedToeflTag === toeflTag.slug.en ? 'active' : ''}`
          "
          @click="() => selectToeflTags(toeflTag.slug.en)"
        >
          {{ toeflTag.name[lang] || toeflTag.name.en }}
        </span>
      </div>
    </div>
    <div v-if="cards">
      <div v-if="cards.length < 1" class="mt-2 mb-2">
        <el-alert type="warning" show-icon :closable="false">
          <b>
            {{ $t("toefl.IPText") }}
            <!-- <el-button
              v-if="isNew === 1"
              @click="changeVersion(2)"
              size="small"
              type="text"
              ><i class="el-icon-refresh"></i>
              {{ $t("toefl.index.switch_new") }}</el-button
            > -->
          </b>
        </el-alert>
      </div>
      <div v-if="cards.length > 0">
        <div class="text-center">
          <el-pagination
            @current-change="setPage"
            :page-size="per_page"
            :current-page="page"
            :total="cardsTotal"
            :pager-count="5"
            :hide-on-single-page="true"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <div v-if="testType == 'mock' || testType == 'quick'" class="row">
          <div
            v-for="(test, index) in cards"
            :key="index"
            class="col-xs-12 col-md-6"
          >
            <mock-card
              :test="test"
              :testType="testType"
              :version="isNew"
              :isFreeMember="isFreeMember"
            ></mock-card>
          </div>
        </div>
        <div
          v-if="
            testType == 'reading' ||
              testType == 'listening' ||
              testType == 'speaking' ||
              testType == 'writing'
          "
        >
          <div v-for="(card, key) in cards" :key="key">
            <h3 class="title-tpo">{{ card.title }}</h3>
            <div class="row" v-if="!isBrowse">
              <div class="col-xs-12 col-md-12 col-lg-12">
                <PassageCard
                  v-if="isExistSingleSections(card.toefl_single_sections)"
                  :title="singleSection[testType][lang]"
                  :tagId="
                    (card.toefl_single_sections[0] || card.chapters[0]).tag_id
                  "
                  :card_title="card.title"
                  :testType="testType"
                  :version="isNew"
                  :isFreeMember="isFreeMember"
                ></PassageCard>
              </div>
            </div>
            <div class="row">
              <div
                v-for="(test, index) in card.chapters"
                :key="index"
                class="col-xs-12 col-md-4 col-lg-4"
              >
                <PassageCard
                  :test="test"
                  :title="test.title[lang]"
                  :tagId="test.tag_id"
                  :card_title="card.title"
                  :testType="testType"
                  :version="isNew"
                  :isBrowse="isBrowse"
                  :isFreeMember="isFreeMember"
                ></PassageCard>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div class="text-center">
          <el-pagination
            @current-change="setPage"
            :page-size="per_page"
            :current-page="page"
            :total="cardsTotal"
            :pager-count="5"
            :hide-on-single-page="true"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </div>
      <!-- <el-empty v-else description="No tests"></el-empty> -->
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
/*eslint-enable */
import MockCard from "@/views/toefl/toeflList/components/MockCard.vue";
import PassageCard from "@/views/toefl/toeflList/components/PassageCard.vue";
import Toefl from "@/apis/toefl.js";
import User from "@/apis/user.js";
import roleMixin from "@/mixins/role";

export default {
  components: {
    MockCard,
    PassageCard
  },

  mixins: [roleMixin],

  props: ["testType", "isNew", "isBrowse", "countryCode"],
  data() {
    return {
      cards: null,
      cardsTotal: 0,
      per_page: 0,
      countryCodes: ["TW", "JP", "VN", "IN", "BD", "PK", "SG", "EG"],
      islimit: 0,
      toeflTags: [],
      selectedToeflTag: null,
      settings: {}
    };
  },
  computed: {
    ...mapGetters("user", ["langShortValue", "getProfile"]),
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    lang() {
      return this.langShortValue;
    },
    singleSection() {
      let singleSection = {
        reading: {
          cn: "完整单元（2 篇文章）",
          tw: "完整單元（2 篇文章）",
          en: "Full Section (2 Passages)"
        },
        listening: {
          cn: "完整单元（6 篇文章）",
          tw: "完整單元（6 篇文章）",
          en: "Full Section (6 Passages)"
        },
        speaking: {
          cn: "完整单元（6 篇文章）",
          tw: "完整單元（6 篇文章）",
          en: "Full Section (6 Passages)"
        },
        writing: {
          cn: "完整单元（2 篇文章）",
          tw: "完整單元（2 篇文章）",
          en: "Full Section (2 Passages)"
        }
      };

      if (this.isNew) {
        singleSection = {
          reading: {
            cn: "完整单元（2 篇文章）",
            tw: "完整單元（2 篇文章）",
            en: "Full Section (2 Passages)"
          },
          listening: {
            cn: "完整单元（5 篇文章）",
            tw: "完整單元（5 篇文章）",
            en: "Full Section (5 Passages)"
          },
          speaking: {
            cn: "完整单元（4 篇文章）",
            tw: "完整單元（4 篇文章）",
            en: "Full Section (4 Passages)"
          },
          writing: {
            cn: "完整单元（2 篇文章）",
            tw: "完整單元（2 篇文章）",
            en: "Full Section (2 Passages)"
          }
        };
      }

      return singleSection;
    },
    includesAbleCountryCodes() {
      return this.countryCodes.includes(this.countryCode);
    },
    proToeflTags() {
      return [
        // { slug: "tpo-1-66" },
        // { slug: "tpo-67-75" },
        { slug: "etsr-official" },
        { slug: "tpo-67-72" },
        { slug: "past-exams" },
        { slug: "ztpo" }
      ];
    },
    isFreeMember() {
      return this.$store.getters["user/isFreeMember"];
    },
    isIncludeOldPro() {
      return this.$store.getters["user/isIncludeOldPro"];
    },
    getIsPro() {
      return this.$store.getters["user/getIsPro"];
    }
  },
  watch: {
    testType() {
      this.getToefls();
    },
    page() {
      this.getToefls();
    },
    countryCode() {
      this.getToefl();
    }
  },

  async mounted() {
    const { settings } = await User.getSetting();
    this.settings = settings;
    if (this.countryCode) {
      this.getToefl();
    }
  },

  methods: {
    changeVersion(val) {
      this.routerPush({
        query: {
          ...this.$route.query,
          page: 1,
          isNew: val
        }
      });
    },
    isExistSingleSections(singleSections) {
      if (Array.isArray(singleSections)) {
        return singleSections.length > 0;
      }
      return Boolean(singleSections);
    },
    async getToefl() {
      await this.getToeflTags();

      if (
        !this.includesAbleCountryCodes ||
        (!this.includesAbleCountryCodes && this.isFreeMember)
      ) {
        this.islimit = 1;
      }

      if (this.isRoleAdmin()) {
        this.islimit = 0;
      }
      this.getToefls();
    },
    selectToeflTags(selectToeflTag) {
      if (this.selectedToeflTag === selectToeflTag) {
        this.selectedToeflTag = "";
      } else {
        this.selectedToeflTag = selectToeflTag;
      }
      this.goToNextRoute({
        tag: this.selectedToeflTag,
        page: 1
      });
    },
    isProToeflTag(tag) {
      return this.proToeflTags.some(({ slug }) => slug === tag.slug.en);
    },
    async getToeflTags() {
      const tagsMap = new Map();
      tagsMap.set("barrons", 39);
      tagsMap.set("testadmit-mock", 40);
      tagsMap.set("tpo-1-66", 44);

      const checkAndFilterPermissionTags = toeflTags => {
        // const COPYRIGHT_COUNTRY_CODES = ["TW", "US", "HK", "SG"];
        const COPYRIGHT_COUNTRY_CODES = ["US"];
        const NOT_AT_CARE_COPYRIGHT_COUNTRIES = !COPYRIGHT_COUNTRY_CODES.includes(
          this.countryCode
        );
        if (this.getIsPro && NOT_AT_CARE_COPYRIGHT_COUNTRIES) {
          return toeflTags;
        }

        const tagForNewProMember = [
          tagsMap.get("barrons"),
          tagsMap.get("testadmit-mock")
        ];
        if (
          this.settings?.tpo_1_66_test_is_show_for_free ||
          NOT_AT_CARE_COPYRIGHT_COUNTRIES
        ) {
          tagForNewProMember.push(tagsMap.get("tpo-1-66"));
        }
        return toeflTags.filter(({ id }) => tagForNewProMember.includes(id));
      };

      let { tags } = await Toefl.getToeflTags();
      if (!this.includesAbleCountryCodes && this.isFreeMember) {
        const moveToFirstTags = [
          tagsMap.get("testadmit-mock"),
          tagsMap.get("barrons")
        ];
        moveToFirstTags.forEach(moveToFirstTag => {
          const targetIndex = tags.findIndex(({ id }) => id === moveToFirstTag);
          if (targetIndex !== -1) {
            const targetTag = tags[targetIndex];
            tags.splice(targetIndex, 1);
            tags = [targetTag, ...tags];
          }
        });
      }
      tags.forEach((tag, index) => {
        if (tag.slug.en === "tpo-67-73") {
          tags[index].name.en = "Tpo 67-75";
          tags[index].slug.en = "tpo-67-75";
        }
      });
      console.log(tags);

      if (this.isIncludeOldPro || this.isRoleAdmin()) {
        this.toeflTags = tags;
      } else {
        this.toeflTags = checkAndFilterPermissionTags(tags);
      }

      this.selectedToeflTag = this.toeflTags[0].slug.en;
    },
    async getToefls() {
      let type = this.testType;
      if (type === "mock" || type === "quick" || type === "single") {
        type = "mock";
      }
      this.selectedToeflTag = this.$route.query.tag || this.selectedToeflTag;
      const res = await Toefl.getToefls({
        type: type,
        page: this.page,
        is_new: this.isNew,
        ip_limit: this.islimit,
        tags: this.selectedToeflTag ? [this.selectedToeflTag] : null
      });
      this.per_page = res.per_page;
      this.cards = res.data;
      this.cardsTotal = res.total;
    },
    setPage(page) {
      this.goToNextRoute({ page });
    },
    goToNextRoute(query) {
      let name = "LandingPage";
      if (this.isBrowse) {
        name = "Browse";
      }
      this.routerPush({
        name: name,
        query: {
          ...this.$route.query,
          ...query
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.btn {
  /* width: max-content; */
  /* height: 35px; */
  /* line-height: 35px; */
  cursor: pointer;
  background-color: #f5f5f5;
  color: var(--themeColor);
  text-align: center;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 12px;
  /* padding: 0 20px; */
  /* margin-bottom: 12px;
  margin-right: 12px; */
}
.btn:hover,
.active {
  color: white;
  background: var(--themeColor);
  box-shadow: 1px 3px 6px #aaa;
}
.inactive,
.btn.inactive:hover {
  cursor: default;
  background: #f5f5f5;
  box-shadow: none;
}

@media screen and (max-width: 992px) {
  .title-tpo {
    font-size: 24px;
  }
}
.ivy-type-title-xs {
  display: none;
}
@media screen and (max-width: 992px) {
  .ivy-type-title-xs {
    display: block;
    text-align: center !important;
    font-size: 20px;
  }
  .col-sm-3.col-xs-6.col-md-3 {
    width: 50%;
    padding: 0 5px;
  }
  .type-btn {
    padding: 0 10px;
  }
}
</style>
