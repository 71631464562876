var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.toeflTags.length > 0)?_c('div',{staticClass:"row type-btn"},_vm._l((_vm.toeflTags),function(toeflTag){return _c('div',{key:toeflTag.slug.en,staticClass:"col-md-3 col-sm-6 col-xs-6"},[(_vm.isProToeflTag(toeflTag))?[(_vm.isFreeMember)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('toefl.Pro Plans'),"placement":"top"}},[_c('el-button',{staticClass:"btn",staticStyle:{"border":"none"},attrs:{"disabled":""}},[_c('i',{staticClass:"fas fa-lock"}),(
                toeflTag.slug.en == 'tpo-1-66' ||
                  toeflTag.slug.en == 'tpo-67-75'
              )?_c('span',[_vm._v(" TestAdmit Mock 1 ")]):_c('span',[_vm._v(" "+_vm._s(toeflTag.name[_vm.lang] || toeflTag.name.en)+" ")])])],1):_c('span',{class:("btn " + (_vm.selectedToeflTag === toeflTag.slug.en ? 'active' : '')),on:{"click":function () { return _vm.selectToeflTags(toeflTag.slug.en); }}},[_c('i',{staticClass:"fas fa-unlock"}),_vm._v(" "+_vm._s(toeflTag.name[_vm.lang] || toeflTag.name.en)+" ")])]:_c('span',{class:("btn " + (_vm.selectedToeflTag === toeflTag.slug.en ? 'active' : '')),on:{"click":function () { return _vm.selectToeflTags(toeflTag.slug.en); }}},[_vm._v(" "+_vm._s(toeflTag.name[_vm.lang] || toeflTag.name.en)+" ")])],2)}),0):_vm._e(),(_vm.cards)?_c('div',[(_vm.cards.length < 1)?_c('div',{staticClass:"mt-2 mb-2"},[_c('el-alert',{attrs:{"type":"warning","show-icon":"","closable":false}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("toefl.IPText"))+" ")])])],1):_vm._e(),(_vm.cards.length > 0)?_c('div',[_c('div',{staticClass:"text-center"},[_c('el-pagination',{attrs:{"page-size":_vm.per_page,"current-page":_vm.page,"total":_vm.cardsTotal,"pager-count":5,"hide-on-single-page":true,"layout":"prev, pager, next"},on:{"current-change":_vm.setPage}})],1),(_vm.testType == 'mock' || _vm.testType == 'quick')?_c('div',{staticClass:"row"},_vm._l((_vm.cards),function(test,index){return _c('div',{key:index,staticClass:"col-xs-12 col-md-6"},[_c('mock-card',{attrs:{"test":test,"testType":_vm.testType,"version":_vm.isNew,"isFreeMember":_vm.isFreeMember}})],1)}),0):_vm._e(),(
          _vm.testType == 'reading' ||
            _vm.testType == 'listening' ||
            _vm.testType == 'speaking' ||
            _vm.testType == 'writing'
        )?_c('div',_vm._l((_vm.cards),function(card,key){return _c('div',{key:key},[_c('h3',{staticClass:"title-tpo"},[_vm._v(_vm._s(card.title))]),(!_vm.isBrowse)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 col-lg-12"},[(_vm.isExistSingleSections(card.toefl_single_sections))?_c('PassageCard',{attrs:{"title":_vm.singleSection[_vm.testType][_vm.lang],"tagId":(card.toefl_single_sections[0] || card.chapters[0]).tag_id,"card_title":card.title,"testType":_vm.testType,"version":_vm.isNew,"isFreeMember":_vm.isFreeMember}}):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"row"},_vm._l((card.chapters),function(test,index){return _c('div',{key:index,staticClass:"col-xs-12 col-md-4 col-lg-4"},[_c('PassageCard',{attrs:{"test":test,"title":test.title[_vm.lang],"tagId":test.tag_id,"card_title":card.title,"testType":_vm.testType,"version":_vm.isNew,"isBrowse":_vm.isBrowse,"isFreeMember":_vm.isFreeMember}})],1)}),0),_c('hr')])}),0):_vm._e(),_c('div',{staticClass:"text-center"},[_c('el-pagination',{attrs:{"page-size":_vm.per_page,"current-page":_vm.page,"total":_vm.cardsTotal,"pager-count":5,"hide-on-single-page":true,"layout":"prev, pager, next"},on:{"current-change":_vm.setPage}})],1)]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }